<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>{{ $t('admin.closing_days') }}</h1>
      <div class="meta">
        <a @click="modal_add = true" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus"
            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor"
              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
            </path>
          </svg>
          <span>Schließtag hinzufügen</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>
      <div v-if="closing_days.length == 0" class="alert alert-info">
        <p>Aktuell sind noch keine Schließtage vorhanden.</p>
      </div>
      <div v-else>
        <table class="table">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Standort</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="closing_day in closing_days" v-bind:key="closing_day.id">
              <td width="45%">
                {{ closing_day.closing_day_formatted }}
              </td>
              <td width="45%">
                <span v-if="closing_day.location">{{ closing_day.location.name }}</span>
                <span v-else>Alle Standorte</span>
              </td>
              <td width="10%" class="actions">
                <a @click="delete_closing_day(closing_day)">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add">
      <svg @click="modal_add = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>Schließtag hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap">
          <label for="name" class="form-label">Datum</label>
          <v-date-picker v-model="closing_day.closing_day" :min-date='cal_min_date' :first-day-of-week="2" locale="de"
            color="red" mode="date">
            <template v-slot="{ inputValue, inputEvents }">
              <input class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 form-input"
                :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </div>

        <div class="form-wrap select-wrap">
          <label for="category_id">Standort</label>
          <select v-model="closing_day.location_id" class="form-input">
            <option v-for="location in computed_locations" v-bind:key="location.id" v-bind:value="location.id">{{
              location.name }}
            </option>
          </select>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_closing_day" class="button button-red button-100">Schließtag erstellen ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
export default {
  name: 'admin_closing_days',
  data() {
    return {
      loading: true,
      modal_add: false,
      closing_days: [],
      closing_day: {},
      locations: [],
      user_id: null,
      user: {},
      user_location: null,
    }
  },
  computed: {
    computed_locations: function () {
      var locations = [];
      this.locations.forEach(location => {
        if (this.user_location) {
          if (this.user_location == location.id) {
            locations.push(location);
          }
        } else {
          locations.push(location);
        }
      })
      return locations;
    },
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API + '/user', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user_id = response.data.user.id;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user_id, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
          if (this.user.locations && this.user.locations.length > 0) {
            this.user_location = this.user.locations[0].id;
          }
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.locations = response.data.locations;
        })
      if (this.user_location) {
        this.class_location = this.user_location
      }
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/closing_days', { params: { location_id: this.class_location }, headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.closing_days = response.data.closing_days;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.locations = response.data.locations;
        })
      this.cal_min_date = new Date();
      this.loading = false;
    },
    create_closing_day() {
      this.$http.post(process.env.VUE_APP_BASE_API + '/admin/closing_days', this.closing_day, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_add = false;
          this.closing_day = {};
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    delete_closing_day(closing_day) {
      if (confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API + '/admin/closing_days/' + closing_day.id, { headers: { Authorization: this.$store.getters.get_token } })
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success"
            });
          })
      }
    },
  },
  mounted() {
    this.get_data();
  }
}
</script>
